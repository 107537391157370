<template>
	<!-- 派车单详情 -->
	<div class="detail" :class="themeClass">
		<div class="flex-row margin-b-5" style="margin-top: 20px;margin-bottom: 20px;">
			您的位置：
			<!-- <breadcrumb></breadcrumb> -->
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item v-for="(item,index) in breadList" :key="index" :to="{ path: item.path }">
					{{item.meta.title}}
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div>
			<div class="top flex-row">
				<div class="vehicle-info common common-top font-color-fff font-size13 position-relative">
					<div class="title">车辆信息:</div>
					<div>
						<ul>
							<li>
								车辆:&nbsp;{{ deliverOrderDetails.fPlateNumber }}&nbsp;{{ deliverOrderDetails.fDriver }}&nbsp;{{ deliverOrderDetails.fDriverPhone }}
							</li>
							<li>派车量:&nbsp;{{ deliverOrderDetails.fScheduleVanAmount }}吨</li>
							<li>商品:&nbsp;{{ deliverOrderDetails.fGoodsFullName }}</li>
						</ul>
					</div>
					<div class="position-absolute" style="right: 10px;top: 40px;">
						<imageBox :width='118' :src="require('@/assets/sendCar/ubc.png')"></imageBox>
					</div>
				</div>
				<div class="address-info common common-top margin-l-10 position-relative">
					<div class="title">地址信息:</div>
					<div>
						<ul>
							<li>
								收货人:&nbsp;{{ deliverOrderDetails.fConsignee }}&nbsp;{{ deliverOrderDetails.fConsigneePhone }}
							</li>
							<li>工程:&nbsp;{{ deliverOrderDetails.fProjectName == "" ? "无" : deliverOrderDetails.fProjectName }}</li>
							<li>地址:&nbsp;{{ deliverOrderDetails.fDeliveryAddress }}</li>
						</ul>
					</div>
					<div class="position-absolute" style="right: 0;top: 0;">
						<imageBox :width='118' :height="118" :src="require('@/assets/sendCar/dzxx.png')"></imageBox>
					</div>
				</div>
			</div>
			<div class="bottom flex-row margin-t-10" :style="deliverOrderDetails.fDeliveryID == '4'?'height: 155px;':''">
				<div class="dispatch-list-info common border-F2F2F2">
					<div class="title">派车单信息:</div>
					<ul class="font-color-666">
						<li>派车单号:&nbsp;{{ deliverOrderDetails.fDeliveryOrderNumber }}<span v-if="deliverOrderDetails.fIsMonitor == 1"  style="color: blue;cursor: pointer;" @click="lookMap(deliverOrderDetails.fDeliveryOrderNumber)">（查看轨迹）</span></li>
						<li>订单号:&nbsp;{{ deliverOrderDetails.fOrderNumber }}</li>
						<li v-if="deliverOrderDetails.fDeliveryID != '4'">皮重过磅时间:&nbsp;{{ deliverOrderDetails.fTareTime?deliverOrderDetails.fTareTime:'--' }}</li>
						<li v-if="deliverOrderDetails.fDeliveryID != '4'">皮重信息:&nbsp;{{ deliverOrderDetails.fTare }}吨</li>
						<li v-if="deliverOrderDetails.fDeliveryID != '4'">毛重过磅时间:&nbsp;{{ deliverOrderDetails.fGrossTime?deliverOrderDetails.fGrossTime:'--' }}</li>
						<li v-if="deliverOrderDetails.fDeliveryID != '4'">毛重信息:&nbsp;{{ deliverOrderDetails.fGross }}吨</li>
						<li>制单时间:&nbsp;{{ deliverOrderDetails.fCreateTime?deliverOrderDetails.fCreateTime:'--' }}</li>
						<li>备注:&nbsp;{{ deliverOrderDetails.fRemark == "" ? "无" : deliverOrderDetails.fRemark }}</li>
						<!-- 出厂赠送积分和定向送达积分 没有不显示 -->
						<li v-if="deliverPoint">出厂赠送积分:&nbsp;{{deliverPoint}}</li>
						<li v-if="monitorDelivery">定向送达积分:&nbsp;{{monitorDelivery}}</li>
					</ul>
				</div>
				<div class="flex-column-start-start padding-t-15 send-car-state margin-l-10 border-F2F2F2">
					<div class="margin-l-15">派车状态：</div>
					<div class="margin-l-5 margin-t-10">
						<TimeLine v-model="timeLineData" direction="column"></TimeLine>
					</div>
				</div>
			</div>
			<div class="btn flex-row-center-flex-end">
				<div class="pointer border-color-theme margin-r-10" @click="goDispatchDetails">派车详情</div>
				<!-- 提货码 是 只要有派车单就会生成 -->
				<el-popover v-if="fEnableSendPassWordStatus ==1" placement="right" width="233" trigger="click" @show="popoverShow()"
				 @hide="popoverHide">
					<div class="delivery-code-box flex-colum-space-between">
						<div id="captureId" class="delivery-code-item flex-colum-space-between">
							<div class="font-size16 font-weight700">提货码：{{ qrCoderContext }}</div>
							<img :src="imageUrl" />
						</div>
						<div @click="copyInfo()" class="copy-btn flex-row-center-center pointer">
							复制提货信息
						</div>
						<!-- <el-button style="width: 202px;margin-top: 10px;" plain :loading="true" size="medium">下载图片</el-button> -->
					</div>
					<div slot="reference" class="pointer background-color-theme margin-r-20">提货码</div>
				</el-popover>
			</div>
		</div>
		<mapTrack v-if="mapShow" :showModel="mapShow" :fRemarkOrder="selectRemarkOrder" @change="mapShowChange"></mapTrack>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import SERVER from '../../../../config/config.json'
	import breadcrumb from "@/components/breadcrumb.vue";
	import TimeLine from '@/components/business/TimeLine.vue';
	import imageBox from '@/components/imageBox/imageBox.vue'
	import html2canvas from 'html2canvas'
	import QRCode from 'qrcodejs2'
	import mapTrack from "./mapTrack.vue" //车辆轨迹显示
	export default {
		name: "",
		components: {
			breadcrumb,
			TimeLine,
			imageBox,
			mapTrack
		},
		props: {},
		data() {
			return {
				selectRemarkOrder:'',//车辆轨迹需要的派车单号
				mapShow:false,//车辆轨迹弹框显示
				// 提货码是否隐藏
				fEnableSendPassWordStatus: "",

				breadList: [],
				// 派车订单ID 
				fDeliveryOrderID: null,
				// 派车订单信息
				deliverOrderDetails: {},
				// 时间线数据
				timeLineData: [{
					checked: false,
					title: "已派车",
					time: "",
					name: ""
				}, {
					checked: false,
					title: "已过皮重",
					time: ""
				}, {
					checked: false,
					title: "已过毛重",
					time: ""
				}, {
					checked: false,
					title: "未签收",
					time: ""
				}],
				timeLineData1: [{
					checked: false,
					title: "已派车",
					time: "",
					name: ""
				}, {
					checked: false,
					title: "未签收",
					time: ""
				}],
				QRCodeImg: '',
				qrCoderContext: "",
				qrCoderText: "",
				imageUrl: '',
				deliverPoint: 0, // 出厂积分
				monitorDelivery: 0, // 限时送达积分
			};
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		mounted() {
			if (this.$route.name == 'SendCarDetail') {
				sessionStorage.setItem('itemPath', 'SendCarDetail')
			}
			if (this.$route.query.detailsname == '全部派车单') {
				sessionStorage.ApplicationName = this.$route.query.detailsname.slice(0, -1)
			} else {
				sessionStorage.ApplicationName = this.$route.query.detailsname
			}
			let data = [{
				path: this.$route.path,
				meta: {
					title: this.$route.meta.title
				}
			}];
			this.breadList = [{
				path: this.$route.query.detailspath,
				meta: {
					title: this.$route.query.detailsname
				}
			}].concat(data);
			console.log(this.$route.query.id);
			this.fDeliveryOrderID = this.$route.query.id;
			this.fEnableSendPassWordStatus = this.$route.query.fEnableSendPassWordStatus;
			// 获取派车订单信息
			this.getdeliverOrderDetails()
			this.getPoint()
		},
		methods: {
			// 获取出厂送达积分
			getPoint() {
				this.ApiRequestPost("api/mall/ebsale/deliver-order/get-deliver-order-give-poin", {
					fDeliveryOrderID: this.fDeliveryOrderID
				}).then(res => {
					console.log(res);
					this.deliverPoint = res.obj.deliverPoint // 出厂积分
					this.monitorDelivery = res.obj.monitorDelivery // 限时送达积分
				}, error => {})
			},
			//点击派车单号，查询对应的车辆轨迹
			lookMap(e){
				this.mapShow = true
				this.selectRemarkOrder =  Number(e)
			},
			//车辆轨迹的回调
			mapShowChange(e){
				this.mapShow = e
			},
			// 提货码显示
			popoverShow() {
				let url = SERVER.Environment == 'test' ? SERVER.API_SERVER : window.API_SERVER;
				this.imageUrl = url +
					'api/mall/ebsale/deliver-order/get-d-code-png/' + this.fDeliveryOrderID +
					'.png'
				this.getQRCodeByDeliveryList();
				// this.getDCodePng();
			},
			// 根据派车单ID 获取提货码信息
			getQRCodeByDeliveryList() {
				this.ApiRequestPostNOMess('api/mall/ebsale/deliver-order/get-qr-code-text', {
					id: this.deliverOrderDetails.fDeliveryOrderID
				}).then(res => {
					this.qrCoderContext = res.obj.password
					// this.qrCoderText= res.obj.text

					this.qrCoderText = res.obj.text.replace(/<br>/gi, ""); //清除"数字"和"."以外的字符
					// this.createQrCode(res.obj.password)
				}, error => {})
			},
			// 生成二维码
			createQrCode(qrCoderContext) {
				let RQDpcument = document.getElementById('qrCodeDivID')
				RQDpcument.innerHTML = ""
				this.QRCode = new QRCode(RQDpcument, {
					width: 140,
					height: 140,
					text: qrCoderContext, // 二维码地址 TODO 
					colorDark: "#000", // 二维码颜色
					colorLight: "#fff" // 二维码背景色
				});
			},
			popoverHide() {
				// this.QRCode.clear()
			},
			// 生成涵盖二维码和文字的图片
			createQRCodeImg() {
				// 根据ID获取元素信息
				let documentData = document.querySelector('#captureId')
				// 调用方法将dom信息通过画布画出来
				html2canvas(documentData)
					.then(canvas => {
						// 将画布画好的数据转为图片
						let imgData = canvas.toDataURL("image/jpeg");
						// this.QRCodeImg = imgData;
						let event = new MouseEvent("click"); // 创建一个单击事件
						let a = document.createElement("a"); // 生成一个a元素
						a.href = imgData; // 将生成的URL设置为a.href属性
						a.download = "photo.jpg"; // 设置图片名称
						a.dispatchEvent(event); // 触发a的单击事件
					})
					.catch(error => {});
			},
			// 复制提货信息
			copyInfo() {
				let refCopyInfo = this.qrCoderText
				var input = document.createElement("input"); // js创建一个input输入框
				input.value = refCopyInfo; // 将需要复制的文本赋值到创建的input输入框中，this.ruleForm.url这个是我要复制的内容
				document.body.appendChild(input); // 将输入框暂时创建到实例里面
				input.select(); // 选中输入框中的内容
				document.execCommand("Copy"); // 执行复制操作
				document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
				this.tipsInfo("复制提货信息成功")
			},
			// 获取派车订单信息
			getdeliverOrderDetails() {
				this.ApiRequestPostNOMess("api/mall/ebsale/deliver-order/get", {
					id: this.fDeliveryOrderID
				}).then(async res => {
					console.log(res);
					res.obj.fDeliveryAddress = res.obj.fDeliveryAddress.replace(/\\/g, '');
					if (res.obj.fTareTime) {
						res.obj.fTareTime = this.getTime(res.obj.fTareTime)
					}
					if (res.obj.fGrossTime) {
						res.obj.fGrossTime = this.getTime(res.obj.fGrossTime)
					}
					if (res.obj.fCreateTime) {
						res.obj.fCreateTime = this.getTime(res.obj.fCreateTime)
					}
					if (res.obj.fCarrSignTime) {
						res.obj.fCarrSignTime = this.getTime(res.obj.fCarrSignTime)
					}
					if (res.obj.fCustSignTime) {
						res.obj.fCustSignTime = this.getTime(res.obj.fCustSignTime)
					}
					this.deliverOrderDetails = await res.obj
					// this.$store.commit("changeCommonData", {
					// 	id: this.deliverOrderDetails.fOrderID
					// });
					this.makeTimeLineData();
				}).catch(err => {

				})
			},
			// 组合时间线组件数据
			makeTimeLineData() {
				const orderData = this.deliverOrderDetails
				if (orderData.fDeliveryID != '4') {
					for (let index = 0; index < 5; index++) {
						if ((index + 1) <= orderData.fStatus) {
							switch ((index + 1)) {
								case 1:
									this.timeLineData[0].name = orderData.fCreator
									this.timeLineData[0].time = this.getTime(orderData.fCreateTime)
									this.timeLineData[0].checked = true;
									break;
								case 2:
									break;
								case 3:
									this.timeLineData[1].time = this.getTime(orderData.fTareTime)
									this.timeLineData[1].checked = true;
									break;
								case 4:
									this.timeLineData[2].time = this.getTime(orderData.fGrossTime)
									this.timeLineData[2].checked = true;
									break;
								case 5:
									switch (orderData.fCustSign) {
										case 0:
											this.timeLineData[3].title = "未签收"
											break;
										case 1:
											this.timeLineData[3].title = orderData.fCustReceiver + "已签收"
											break;
									}
									this.timeLineData[3].time = orderData.fCustSignTime ? this.getTime(orderData.fCustSignTime) : ''
									this.timeLineData[3].checked = true;
									break;
							}
						}
					}
				} else {
					for (let index = 0; index < 5; index++) {
						if ((index + 1) <= orderData.fStatus) {
							switch ((index + 1)) {
								case 1:
									this.timeLineData1[0].name = orderData.fCreator
									this.timeLineData1[0].time = this.getTime(orderData.fCreateTime)
									this.timeLineData1[0].checked = true;
									break;
								case 2:
									break;
								case 3:
									break;
								case 4:
									break;
								case 5:
									switch (orderData.fCustSign) {
										case 0:
											this.timeLineData1[1].title = "未签收"
											break;
										case 1:
											this.timeLineData1[1].title = orderData.fCustReceiver + "已签收"
											break;
									}
									this.timeLineData1[1].time = orderData.fCustSignTime ? this.getTime(orderData.fCustSignTime) : ''
									this.timeLineData1[1].checked = true;
									break;
							}
							this.timeLineData = this.timeLineData1;
						}
					}
				}
			},
			goDispatchDetails() {
				// this.$store.commit("changeCommonData", {
				// 	id: this.deliverOrderDetails.fOrderID
				// });
				this.$router.push({
					name: "OrderSendCarDetail",
					query: {
						id: this.deliverOrderDetails.fOrderID,
						detailspath: this.$route.path,
						detailsname: this.$route.meta.title
					}
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.QRcode-text {
		white-space: pre-line;
	}

	// 提货码 包裹盒子
	.delivery-code-box {
		width: 100%;
		min-height: 328px;
		padding-top: 8px;
	}

	.delivery-code-item {
		margin: 10px 0;
		width: 202px;
		min-height: 270px;
	}

	.common {
		flex: 1;
		padding-left: 15px;
		padding-top: 15px;

		.title {
			margin-bottom: 10px;
		}
	}

	.copy-btn {
		width: 200px;
		height: 34px;
		border: 1px solid #0173FE;
		color: #0173FE;
	}

	ul {
		li {
			line-height: 24px;
			height: 24px;
		}
	}

	.common-top {
		height: 118px;
		// background: linear-gradient(45deg, #E8FDFF, #EAEFFF, #E8FDFF, );
		// opacity: 0.8;
		border-radius: 5px;
	}

	.detail {
		max-width: 1100px;
		min-width: 1100px;
		margin: 0 auto;

		.top {
			width: 100%;
		}
	}

	.bottom {
		height: 293px;
	}

	.btn {
		height: 69px;
		margin-top: 46px;
		background-color: #F8F9FE;

		div {
			float: right;
			width: 120px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			box-sizing: border-box;

			&:nth-child(n+2) {
				margin-left: 15px;
			}
		}
	}

	.send-car-state {
		flex: 1;
	}

	.vehicle-info {
		background-color: #8282ff;
	}

	.address-info {
		background-color: #F2F2F2;
	}
</style>
