<template>
	<div>
		<el-dialog id='dialog' title="车辆运行轨迹展示" :visible.sync="importVisible" width="50%"
			:before-close="handleCloseSelect">
			<div class="page-box" style="padding:10px 0px 0px;">
				<div style="width: 100%;height:calc(60vh - 185px)">
					<div id="container" style="width: 100%;height:100%;" v-loading="tableLoading"></div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handleCloseSelect">关闭</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import SERVER from "../../../../config/config.json"
	export default {
		props: {
			showModel: {
				type: Boolean,
				default: false
			},
			fRemarkOrder: {
				type: Number,
			}
		},
		mounted() {
			this.importVisible = this.showModel
			this.form.fRemarkOrder = this.fRemarkOrder
			// var script = document.createElement("script");
			// script.type = "text/javascript";
			// script.src = "https://webapi.amap.com/maps?v=1.4.15&key=" + SERVER.gaodeMapKey;
			// script.onload = script.onreadystatechange = () => {
			// 	if (!this.readyState || this.readyState === "loaded" || this.readyState === "complete") {
			// 		this.initMap()
			// 		script.onload = script.onreadystatechange = null
			// 	}
			// }
			// document.body.appendChild(script)
			this.RetrieveList()
		},
		data() {
			return {
				allFence: [],
				importVisible: false, //弹框的穿线
				echargeList: [], //参与计算的数据
				dialogVisible: false,
				tableLoading: false, //地图数据加载的动画
				form: {
					fRemarkOrder: '', //派车单号
				},
				map: null,
				marker: [],
				AlllineList: [],
				startMarker: [],
				endMarker: [],
				district: '',
				opts: {
				  subdistrict: 0, // 获取边界不需要返回下级行政区
				  extensions: 'all', // 返回行政区边界坐标组等具体信息
				  level: 'district' // 查询行政级别为 市
				},
				showbiz: false,
				polygonData: [],
				myarr:[]
			};
		},
		methods: {
			handleCloseSelect() {
				this.$emit('change', !this.showModel, '')
			},
			// 获取地图数据
			RetrieveList() {
				this.tableLoading = true
				let params = {}
				params.fMonitorType = 1
				params.filter = this.form.fRemarkOrder
				this.ApiRequestPostNOMess('/api/mall/ebmonitor-delivery-order/get-vehicle-locus', params).then(
					result => {
						this.tableLoading = false
						this.AlllineList = []
						this.marker = []
						this.startMarker = []
						this.endMarker = []
						this.polygonData = []
						this.selectList = result.obj
						if(result.obj.length !=0){
							for (let item of result.obj) {
								if (item.fList.length != 0) {
									this.AlllineList.push({
										fList: item.fList,
										line: [],
										fStatus: item.fStatus
									})
									this.fenceData(item.fAreaID)
								} else {
									if (item.getRecord != null) {
										this.AlllineList.push({
											fList: [item.getRecord],
											line: [],
											fStatus: item.fStatus
										})
									}
									this.fenceData(item.fAreaID)
								}
							}
							for (let item of this.AlllineList) {
								for (let sonitem of item.fList) {
									item.line.push([sonitem.flng, sonitem.flat])
									sonitem.fTime = this.getTime(sonitem.fTime)
								}
							}
						}else{
							if (this.map != null) {
								this.map.destroy()
								this.initMap()
							}else{
								this.initMap()
							}
						}
					},
					error => {
						this.tableLoading = false
					}
				)
			},
			//获取电子围栏的接口调用
			fenceData(val){
				this.ApiRequestPostNOMess('/api/mall/ebmonitor/sale-area-fence/get-list-area-async-hd', {fAreaID:val}).then(res => {
				  this.myarr=res.obj.items
				  if (this.map != null) {
				  	this.map.destroy();
				  	this.initMap()
				  }else{
				  	this.initMap()
				  }
				})
			},
			initMap() {
				var that = this
				that.map = new AMap.Map("container", {
					resizeEnable: true,
					center: [108.947222, 34.275762], //初始地图中心点,
					zoom: 4, //初始地图级别
					animateEnable: true
				});
				that.district = new AMap.DistrictSearch(this.opts, this.showbiz)
				var polyline = []
				var passedPolyline = []
				for (let item of this.AlllineList) {
					that.marker.push(new AMap.Marker({
					  map: that.map,
					  position: item.line[0],
					  icon:require('@/assets/huocheCar.png'),
					  offset: new AMap.Pixel(-40, -15),
					  autoRotation: true,
					  angle: -90
					}))
					// 绘制轨迹
					polyline.push(new AMap.Polyline({
						map: that.map,
						path: item.line,
						showDir: true,
						strokeColor: "#28F", //线颜色
						// strokeOpacity: 1,     //线透明度
						strokeWeight: 6 //线宽
						// strokeStyle: "solid"  //线样式
					}))

					passedPolyline.push(new AMap.Polyline({
						map: that.map,
						strokeColor: "#AF5", //线颜色
						// strokeOpacity: 1,     //线透明度
						strokeWeight: 6 //线宽
						// strokeStyle: "solid"  //线样式
					}))
					this.marker.forEach((item, index) => {
						item.on("moving", function(e) {
							passedPolyline[index].setPath(e.passedPath)
						})
					})

				}
				/////////////////////////////////
				for (let item = 0; item < that.myarr.length; item++) {
					console.log(that.myarr[item])
					that.polygonData = that.myarr[item].detailhd;
					if (that.polygonData.length == 0 && that.myarr[item].fIsUseCity == 1) {
						const adcode = that.myarr[item].fGovernmenAreaCode
						that.district.search(adcode, (status, result) => {
							var bounds = result.districtList[0].boundaries
							let path1 = bounds[0]
							const arr = []
							for (let z = 1; z < bounds.length; z++) {
								if (bounds[z].length > path1.length) {
									path1 = bounds[z]
								}
							}
							for (let j = 0; j < path1.length; j++) {
								const obj = []
								obj.push(path1[j].lng)
								obj.push(path1[j].lat)
								arr.push(obj)
							}
							const polygonss = []
							const center = that.calculateCenter(arr)
							for (let x = 0; x < bounds.length; x++) {
								const polygon = new AMap.Polygon({
									trokeWeight: 1,
									path: bounds[x],
									zIndex: that.myarr[item].fGovernmenAreaCode * 1,
									fillOpacity: 0.4,
									fillColor: '#80d8ff',
									strokeColor: '#0091ea',
									extData: {
										fFenceID: that.myarr[item].fFenceID,
										fFence: that.myarr[item].fFence,
										fFenceCode: that.myarr[item].fFenceCode,
										frv: that.myarr[item].frv,
										item: that.myarr[item]
				
									}
								})
								polygonss.push(polygon)
								that.allFence.push(polygon)
								polygon.on('click', function(e) {
									if (that.type === 'circle' || that.type === 'polygon') {
										return
									}
									that.defaultActive = polygon._opts.extData.fFenceCode
									if (that.activeEnclosure !== '' && that.active ===
										'edit') {
										that.$message.warning('请先结束编辑再去操作')
										return
									}
									const polygons = that.map.getAllOverlays('polygon')
									for (var i = 1; i < polygons.length; i++) {
										polygons[i]._opts.fillColor = '#80d8ff'
									}
									const circles = that.map.getAllOverlays('circle')
									for (var i = 0; i < circles.length; i++) {
										circles[i]._opts.fillColor = '#80d8ff'
									}
									polygon.setOptions({
										fillColor: '#1E9FFF',
										fillOpacity: 0.8
									})
									that.delEnclosureList = [{
										fFenceID: e.target._opts.extData.fFenceID,
										frv: e.target._opts.extData.frv
									}]
									that.enclosureName = e.target._opts.extData.fFence
									that.activeEnclosure = polygon
									that.EnclosureType = 2
									if (that.active === 'edit') {
										that.showEditEnclosure()
									} else if (that.active === 'delOne') {
										that.delEnclosure = true
									}
								})
							}
							that.map.add(polygonss)
							that.map.setFitView()
							const textInfo = new AMap.Text({
								text: `${that.myarr[item].fFence}(编号${that.myarr[item].fFenceCode})`,
								style: {
									'background-color': 'rgba(255,255,255,.5)',
									'color': 'red'
								},
								position: center,
								zIndex: 20,
								extData: {
									fFenceID: that.myarr[item].fFenceID
								}
							})
							that.map.add(textInfo)
						})
					}
					for (let i = 0; i < that.polygonData.length; i++) {
						const fence = {}
						fence.id = that.myarr[item].fFenceCode
						fence.name = `${that.myarr[item].fFence}(编号${that.myarr[item].fFenceCode})`
						if (that.myarr[item].fFenceTypeID === 1) {
							const circle = new AMap.Circle({
								center: [that.polygonData[i][0].flng, that.polygonData[i][0].flat],
								radius: that.polygonData[i][0].fRadius, // 半径
								trokeWeight: 1,
								fillOpacity: 0.4,
								zIndex: 1000000,
								fillColor: '#80d8ff',
								strokeColor: '#0091ea',
								extData: {
									fFenceID: that.myarr[item].fFenceID,
									fFence: that.myarr[item].fFence,
									fFenceCode: that.myarr[item].fFenceCode,
									frv: that.myarr[item].frv,
									item: that.myarr[item]
								}
							})
							that.map.add(circle)
							that.allFence.push(circle)
							const textInfo = new AMap.Text({
								text: `${that.myarr[item].fFence}(编号${that.myarr[item].fFenceCode})`,
								zIndex: 21,
								style: {
									'background-color': 'rgba(255,255,255,.5)',
									'color': '#0091ea'
								},
								position: [that.polygonData[i][0].flng, that.polygonData[i][0].flat],
								extData: {
									fFenceID: that.myarr[item].fFenceID
								}
							})
							that.map.add(textInfo)
							that.map.setFitView(textInfo)
							// }
				
						} else {
							let arr = []
							const path = []
							if (that.myarr[item].fIsUseCity === 1) {
								const adcode = that.myarr[item].fGovernmenAreaCode
								that.district.search(adcode, (status, result) => {
									var bounds = result.districtList[0].boundaries
									let path1 = bounds[0]
									const arr = []
									for (let z = 1; z < bounds.length; z++) {
										if (bounds[z].length > path1.length) {
											path1 = bounds[z]
										}
									}
									for (let j = 0; j < path1.length; j++) {
										const obj = []
										obj.push(path1[j].lng)
										obj.push(path1[j].lat)
										arr.push(obj)
									}
									const polygonss = []
									const center = that.calculateCenter(arr)
									for (let x = 0; x < bounds.length; x++) {
										const polygon = new AMap.Polygon({
											trokeWeight: 1,
											path: bounds[x],
											zIndex: this.polygonData[i].fGovernmenAreaCode * 1,
											fillOpacity: 0.4,
											fillColor: '#80d8ff',
											strokeColor: '#0091ea',
											extData: {
												fFenceID: that.myarr[item].fFenceID,
												fFence: that.myarr[item].fFence,
												fFenceCode: that.myarr[item].fFenceCode,
												frv: that.myarr[item].frv,
												item: that.myarr[item]
											}
										})
										polygonss.push(polygon)
										that.allFence.push(polygon)
									}
									that.map.add(polygonss)
									that.map.setFitView()
									const textInfo = new AMap.Text({
										text: `${that.myarr[item].fFence}(编号${that.myarr[item].fFenceCode})`,
										style: {
											'background-color': 'rgba(255,255,255,.5)',
											'color': 'red'
										},
										position: center,
										zIndex: 20,
										extData: {
											fFenceID: that.myarr[item].fFenceID
										}
									})
									that.map.add(textInfo)
								})
							} else {
								arr = that.polygonData[i]
								arr.forEach(item => {
									const obj = []
									obj.push(item.flng)
									obj.push(item.flat)
									path.push(obj)
								})
								const polygon = new AMap.Polygon({
									trokeWeight: 1,
									path: path,
									fillOpacity: 0.4,
									fillColor: '#80d8ff',
									strokeColor: '#0091ea',
									zIndex: 1000000,
									extData: {
										fFenceID: that.myarr[item].fFenceID,
										fFence: that.myarr[item].fFence,
										fFenceCode: that.myarr[item].fFenceCode,
										frv: that.myarr[item].frv,
										item: that.myarr[item]
									}
								})
								that.map.add(polygon)
								that.allFence.push(polygon)
								const center = that.calculateCenter(path)
								const textInfo = new AMap.Text({
									text: `${that.myarr[item].fFence}(编号${that.myarr[item].fFenceCode})`,
									style: {
										'background-color': 'rgba(255,255,255,.5)',
										'color': '#0091ea'
									},
									position: center,
									zIndex: 21,
									extData: {
										fFenceID: that.myarr[item].fFenceID
									}
								})
								that.map.add(textInfo)
							}
						}
						that.map.setFitView()
					}
				}
				////////////////////////////
				
				this.AlllineList.forEach((item, index) => {
					this.startMarker.push(new AMap.Marker({
						map: this.map,
						position: item.line[0], //起点经纬度
						icon: new AMap.Icon({
							// 图标的取图地址
							image: require('@/assets/startDoit.png'),
							// 图标所用图片大小
							imageSize: new AMap.Size(35, 35),
							// 图标取图偏移量
							imageOffset: new AMap.Pixel(-5, 0)
						}), //终点ico
						autoRotation: true,
					}))
					if (item.line.length > 1 && item.fStatus != 1) {
						this.endMarker = new AMap.Marker({
							map: this.map,
							position: item.line[item.line.length - 1], //终点经纬度
							icon: new AMap.Icon({
								// 图标的取图地址
								image: require('@/assets/endDoit.png'),
								// 图标所用图片大小
								imageSize: new AMap.Size(35, 35),
								// 图标取图偏移量
								imageOffset: new AMap.Pixel(-5, 0)
							}), //终点ico
							autoRotation: true,
						});
					}
					//==========================
					//==========================
				})
				this.map.add([this.startMarker, this.endMarker]);
				this.map.setFitView();
			},
			// 获取多变行中心坐标
			calculateCenter(lnglatarr) {
			  var total = lnglatarr.length;
			  var X = 0
			  var Y = 0
			  var Z = 0
			  lnglatarr.map((item) => {
			    var lng = (item[0] * Math.PI) / 180;
			    var lat = (item[1] * Math.PI) / 180;
			    var x, y, z;
			    x = Math.cos(lat) * Math.cos(lng);
			    y = Math.cos(lat) * Math.sin(lng);
			    z = Math.sin(lat);
			    X += x;
			    Y += y;
			    Z += z;
			  });
			  X = X / total;
			  Y = Y / total;
			  Z = Z / total;
			  var Lng = Math.atan2(Y, X);
			  var Hyp = Math.sqrt(X * X + Y * Y);
			  var Lat = Math.atan2(Z, Hyp);
			  return ([(Lng * 180) / Math.PI, (Lat * 180) / Math.PI]);
			},
		}
	};
</script>

<style scoped>
	#container {
		height: 100%;
		width: 100%;
	}

	.input-card .btn {
		margin-right: 1.2rem;
		width: 9rem;
	}

	.input-card .btn:last-child {
		margin-right: 0;
	}

	.btn {
		display: inline-block;
		font-weight: 400;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		border: 1px solid transparent;
		transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
		background-color: transparent;
		background-image: none;
		color: #25A5F7;
		border-color: #25A5F7;
		padding: .25rem .5rem;
		line-height: 1.5;
		border-radius: 1rem;
		cursor: pointer;
	}

	.input-item {
		position: relative;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-ms-flex-align: center;
		align-items: center;
		width: 100%;
		height: 3rem;
	}

	.input-card {
		display: flex;
		flex-direction: column;
		min-width: 0;
		word-wrap: break-word;
		background-color: #fff;
		background-clip: border-box;
		border-radius: .25rem;
		width: 22rem;
		border-width: 0;
		border-radius: 0.4rem;
		box-shadow: 0 2px 6px 0 rgba(114, 124, 245, .5);
		position: fixed;
		bottom: 1rem;
		right: 1rem;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		padding: 0.75rem 1.25rem;
	}

	/deep/ .el-form-item {
		margin-bottom: 0px;
	}

	/deep/ .el-form-item__content {
		margin-left: 70px !important;
	}

	#panel {
		position: fixed;
		background-color: rgba(255, 255, 255, 0.6);
		max-height: 90%;
		top: 160px;
		right: 10px;
		width: 450px;
		border-radius: 6px;
	}

	.amap-call {
		background-color: #009cf9;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		line-height: 22px;
		color: white;
		text-align: left;
		padding: 5px 10px;
	}

	.startl {
		font-size: 14px;
		font-weight: 700;
		text-align: left;
		line-height: 25px;
		border-bottom: 1px solid #f0f0f0;
		padding-left: 10px;
	}

	.endl {
		font-size: 14px;
		font-weight: 700;
		text-align: left;
		line-height: 25px;
		border-bottom: 1px solid #f0f0f0;
		padding-left: 10px;
	}

	.circleA {
		font-size: 12px;
		line-height: 20px;
		padding-left: 10px;
		padding-right: 10px;
		letter-spacing: 1px;
	}

	.beforedt {
		margin-top: -10px;
		width: 8px;
		height: 8px;
		line-height: 20px;
		color: #FFF;
		background-color: #009cf9;
		border-radius: 50%;
		text-align: center;
		font-size: 12px;
		font-weight: normal;
		margin-right: 5px;
	}
</style>
